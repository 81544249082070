/* You can add global styles to this file, and also import other style files */
//Fonts----------------------------------------------------->
@font-face {
  font-family: "lato-bold";
  src: url("../fonts/lato-bold.woff2") format("woff2"),
    url("../fonts/lato-bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "lato-light";
  src: url("../fonts/lato-light.woff2") format("woff2"),
    url("../fonts/lato-light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "lato-regular";
  src: url("../fonts/lato-regular.woff2") format("woff2"),
    url("../fonts/lato-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "lato-black";
  src: url("../fonts/lato-black.woff2") format("woff2"),
    url("../fonts/lato-black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "lato-semibold";
  src: url("../fonts/lato-semibold.woff2") format("woff2"),
    url("../fonts/lato-semibold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "lato-heavy";
  src: url("../fonts/lato-heavy.woff2") format("woff2"),
    url("../fonts/lato-heavy.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "lato-medium";
  src: url("../fonts/lato-medium.woff2") format("woff2"),
    url("../fonts/lato-medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

//Fonts--------------------------------------------------------->
.loader {
  position: absolute;
  left: 50%;
}

.loader-load {
  position: absolute;
  left: 20%;
}

.delete-modal-icon {
  display: block;
  margin-left: auto;
  cursor: pointer;
}

.loader-right {
  position: absolute;
  right: 20%;
}

.top-bar-navigation .filter-drop-down {
  width: 290px;
  height: 180px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #366b91;
}

.dashboard-main .header-menu .a {
  font-family: "lato-regular";
  font-size: 13px;
  color: #ffffff;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
}

//common---------------------------------------------->

.dynamicrow,
.table-head {
  background: #ffffff;
}

.pointer, .cursor {
  cursor: pointer;
}

.main-table .dynamic {
  cursor: pointer;
  background: #ecf3f6;
}

.main-div {
  background: #dde8ee;
}

.main-table {
  margin-bottom: 100px !important;
}

.back-left-icon {
  transform: rotate(180deg);
  cursor: pointer;
  margin-bottom: -10px;
}

.back-left-icon:hover {
  path {
    fill: #81b9d5 !important;
  }
}

.underline {
  text-decoration: underline;
}

.rectangle .sb-avatar .sb-avatar__text{
  background-color: #509ec6 !important;
}

.table-row {
  box-shadow: 0px 1px #81b9d5;

  &:hover {
    background: #bedae6;
  }

  td {
    border-bottom-width: 0 !important;
    text-transform: lowercase;
    text-transform: capitalize;
  }

  .email-text {
    text-transform: lowercase !important;
  }
}

.hhh {
  background: #134067 !important;
}

.candidate-row:hover {
  background: #14b9b8 !important;
}

.table-head {
  box-shadow: 0px 1px #81b9d5;
  background: #ffffff;
}

.s_no {
  padding-left: 20px !important;
}

.play-icon,
.pause-icon {
  cursor: pointer;
}

.pause-img {
  margin-left: 1px;
  width: 17px;
}

.send-to-vote {
  border-radius: 5px;
  background: #509ec6;
  padding: 4px;

  &:hover {
    background: #14b9b8;
  }
}

.reload {
  border-radius: 3px;
  border: 1px solid #509ec6;
  width: 20px;
  height: 23px;
  cursor: pointer;

  svg path:hover {
    fill: #cd4c8d !important;
  }

  &:hover {
    border: 1px solid #cd4c8d;
    svg path {
      fill: #cd4c8d !important;
    }
  }
}

.storage-row {
  box-shadow: 0px 1px #81b9d5;

  &:hover {
    background: #bedae6;
  }

  td {
    border-bottom-width: 0 !important;
    text-transform: capitalize;
  }
}

.storage-row.active {
  background: #85b3cd !important;
}

tr.resourcedata {
  box-shadow: 0px 1px #81b9d5;
}

.storage-tabs {
  background: #386892;
  height: 38px;

  a.nav-link {
    font-size: 13px;
    color: #5f84a4;
    margin-left: 60px;
    cursor: pointer;
    letter-spacing: 2px;
    font-weight: bold;
    border-color: #386892 !important;
  }

  a.nav-link.active {
    background-color: #386892 !important;
    color: none !important;
    border-bottom: 5px solid #14b9b8 !important;
    color: #fff;
    font-family: "lato-regular";
    padding-bottom: 4px;
  }

  a.nav-link:hover {
    color: #ffffff;
    padding-bottom: 5px;
  }
}

.navbar-nav.richard,
.navbar-nav.listclient {
  align-items: center;
}

//Pending section css--------------------------------------------------------------------->
.pending-section {

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
}

.dataclick {
  padding-left: 40px;
  text-align: left;
  width: 22% !important;

  .title {
    font-family: "lato-regular";
    font-weight: bold;
    padding-right: 20px;
    padding-bottom: 10px;
  }

  .sub-title {
    padding-bottom: 10px;
  }
}

.clicks1,
.clicked,
.dataclick {
  text-align: left;

  .title {
    font-family: "lato-regular";
    font-weight: bold;
    padding-right: 20px;
    padding-bottom: 10px;
  }

  .sub-title {
    padding-bottom: 10px;
    word-break: break-all;
  }
}

tr.datacabl,
.contentdata1 {
  background: #f8fbfd;
}

.main-candidate {
  padding: 30px;
  background: #f8fbfd;
}

.extendedrev {
  font-size: 13px;
  width: 100%;
  box-shadow: 0px 1px #81b9d5;
  border-top: 1px solid #81b9d5;
}

.epic-extendedrev {
  font-size: 13px;
}

.sb-avatar {
  margin-top: 5px;
}

button.vote:hover,
button.fill.mt-1:hover {
  background: #14b9b8;
}

button.hiring-btn,
button.hiring-profile-btn {
  height: 29px;
  background: #509ec6;
  border-radius: 3px;
  border: none;
  font-size: 13px;
  color: #fff;
  font-family: "lato-regular";
  max-width: 127px;
  margin-bottom: 13px;
  text-align: left;

  img {
    margin-right: 20px;
  }
}

.stop-btn{
  height: 29px;
  background: #e91640;
  border-radius: 3px;
  border: none;
  font-size: 13px;
  color: #fff;
  font-family: "lato-regular";
  max-width: 127px;
  margin-bottom: 13px;
  text-align: left;
  opacity: .6;
  cursor: not-allowed !important;
  img {
    margin-right: 20px;
  }

}

button.decline-btn {
  height: 29px;
  background: #EEEEEE;
  border-radius: 3px;
  border: none;
  font-size: 13px;
  color: #fff;
  font-family: "lato-regular";
  max-width: 127px;
  margin-bottom: 13px;
  text-align: left;

  img {
    margin-right: 20px;
  }
}

button.hiring-profile-btn:hover {
  background: #14b9b8;
}

button.epic-btn {
  width: 150px;
  height: 29px;
  background: #509ec6;
  border-radius: 3px;
  border: none;
  font-size: 13px;
  color: #fff;
  font-family: "lato-regular";
  margin-bottom: 13px;
  text-align: center;

  &:hover {
    background: #14b9b8;
  }
}

button.hiring {
  width: 150px;
  height: 29px;
  background: #bddaea;
  border-radius: 3px;
  border: none;
  font-size: 13px;
  color: #fff;
  font-family: "lato-regular";
  margin-bottom: 13px;
  text-align: center;
}

.clickn {
  width: 75px;
  height: 29px;
  background: #509ec6;
  border-radius: 3px;
  border: none;
  font-size: 13px;
  color: #fff;
  font-family: "lato-regular";
  text-align: center;
}

textarea#w3review {
  width: 350px;
  height: 110px;
  font-family: "lato-regular";
  font-size: 13px;
  color: #526c80;
  border: none;
  background: transparent;
  padding: 10px;
  overflow: hidden;
  border-radius: 3px;
  resize: none;

  &:hover {
    background: #ecf3f6 !important;
  }

  &:focus-visible {
    outline: none !important;
  }
}

#editNotes {
  width: 100%;
  border-color: transparent;

  &:focus-visible {
    outline: none;
  }
}

table.table.github {
  font-family: "lato-regular";
  text-align: left;
  font-size: 13px;
  color: #526c80;
  background: #ecf3f6;
  margin-bottom: 0;
}

//Pending Modal----------------------------------------------------------->
.modal-dialog {
  max-width: none !important;
}

.modal-header {
  background: #509ec6 !important;
  padding: 6px !important;

  .modal-title {
    margin-left: 33px;
  }
}

.back-arrow-icon {
  cursor: pointer;
}

.back-arrow:hover {
  path {
    fill: #81b9d5 !important;
  }
}

.back-arrow-box {
  path:hover {
    fill: #81b9d5 !important;
  }
}

.scrollbar {
  overflow-y: scroll;
}

.scrollbar-primary {
  scrollbar-color: #85b3cd #f6f6f6;
}

.scrollbar-primary::-webkit-scrollbar {
  width: 12px;
  background-color: #f6f6f6 !important;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #85b3cd !important;
}

.export-up-arrow {
  margin-right: 10px;
}

button.telenory {
  border: none;
  width: 94px;
  height: 33px;
  border-radius: 3px;
  background-color: #bedae6;
  font-family: "lato-regular";
  font-size: 13px;
  color: #134067;
  text-align: center;
  margin-left: 10px;
}

//Notes css-------------------------------------------->
.notes-table {
  border: 2px solid #81b9d5;
  position: fixed;
  bottom: 0;
}

.githu {
  padding-top: 30px;
  background: #dde8ee;
}

//storage management------------------------------------------>
.activehiring {
  padding-left: 0 !important;
}

.semanticd {
  background: #dde8ee;
  min-height: calc(100vh - 50px);
}

.hiringactively {
  padding-right: 0 !important;
  border-right: 2px solid #81b9d5;
}

.education h4 {
  font-family: "lato-bold";
  font-size: 17px;
  color: #526c80;
}

.professor,
.jbondz5,
.bondz {
  text-align: left;

  h4 {
    font-family: "lato-bold";
    font-size: 13px;
    color: #526c80;
  }
}

li.jobe {
  font-family: "lato-regular";
  font-size: 13px;
  color: #526c80;
  list-style: none;
  line-height: 1.6;
}

li.computate {
  font-style: italic;
  font-size: 13px;
  line-height: 1.9;
  list-style: none;
  color: #526c80;
  font-weight: bold;
}

.multipleprod {
  background: #85b3cd;
  color: #ffffff;
  letter-spacing: 2px;
  height: 37px;
}

.oxford,
.jbond2,
.jbond1 {
  padding-left: 0px;
  list-style: none;
}

.storage-title {
  font-weight: bold;
  padding-bottom: 7px;
  font-size: 13px;
  color: #526c80;
}

.storage-sub-title {
  padding-bottom: 7px;
  font-size: 13px;
  color: #526c80;
}

.notesdata.datamine {
  text-align: left;
  font-size: 13px;
}

.edit-btn-section {
  margin-left: 20px;
}

.navbar .container-fluid .edit-bt,
.edit-btn-section .edit {
  background-color: #509ec6;
  border: 1px solid #509ec6;
}

.store-edit-btn-section {
  margin-left: 11px;
}

button.applicant:hover {
  background: #14b9b8;
}

a.modify:hover {
  color: #fff;
}

button.resume {
  width: 125px;
  background: #509ec6;
  border-radius: 3px;
  font-family: "lato-regular";
  font-size: 13px;
  color: #ffffff;
  padding: 6px;
  border: none;
  text-align: center;
  display: flex;

  .resume-link {
    color: #fff;
    text-decoration: none;
  }

  .resume-icon {
    margin-right: 10px;
  }
}

.resume-btn-link {
  width: 125px;
  background: #509ec6;
  border-radius: 3px;
  font-family: "lato-regular";
  font-size: 13px;
  color: #ffffff;
  padding: 6px;
  border: none;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-decoration: none;

  &:hover {
    color: #ffffff;
  }
}

.storage-clicked {
  text-align: left;
}

nav.navbar.navbar-expand-lg.navbar-dashboard {
  background: #134067;
}

.navbar-brand {
  margin-right: 0 !important;
}

//Candidate------------------------------------------------>
button.filtr {
  height: 28px;
  border-radius: 3px;
  background-color: #509ec6;
  background-size: cover;
  margin-top: 9px;
  border: 1px solid #509ec6;
  margin-right: 11px;
  margin-left: 11px;
  font-family: "lato-regular";
  font-size: 13px;
  color: #ffffff;

  img {
    margin-right: 10px;
  }
}

input {
  &.form-control {
    &.multi-select {
      &.softskill {
        width: 124% !important;
      }
    }
  }
}

select.custom-select.pandemic {
  margin-left: 32px !important;
  outline: none;
}

select#inputGroupSelect01 {
  margin-left: 0;
  border: 1px solid #d6dbe0;
  width: 100%;
  height: 29px;
  border-radius: 4px;
  font-size: 13px;
  color: #526c80;
  outline: none;
}

.cityclass7 {
  margin-left: 17px;
}

h4.locatab2 {
  font-family: "lato-regular";
  font-size: 13px;
  margin-top: 0 !important;
}

.techteam {
  padding: 5% !important;
  padding-top: 0 !important;
  border: 2px dotted transparent !important;
}

.locatab {
  font-family: "lato-regular";
  font-size: 13px;
  padding: 8%;
  padding-top: 10px;
  padding-bottom: 17px;
  border: 2px dotted #608eb2;
  border-radius: 3px;
  text-align: left;
}

// header search------------------------------->
input {
  &.form-control {
    &.handle {
      &.handled {
        &.w-auto {
          width: 230px !important;
          height: 29px;
          color: #ffffff;
          padding-left: 25px;
          margin-top: 5px;
          background: #134067;
          border: 1px solid #366b91;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: none;
        }
      }
    }
  }
}

.form-control:focus {
  box-shadow: none !important;
}

.clickeb.d-grid {
  padding-left: 22%;
  height: 94px;
}

.clickeb.d-grid.paddingL {
  padding-left: 15%;
  height: 100%;
}

img.sidearr {
  float: right;
}

a#dropdownMenuLink {
  background: #366b91;
  height: 29px;
  width: 85px;
  margin-top: 5px;
  margin-bottom: 0px;
  padding-top: 3%;
  font-family: "lato-regular";
  font-size: 13px;
  border: 1px solid #366b91;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;

  img {
    margin-right: 8px;
  }

  &:hover {
    background: #14b9b8;
  }
}

.cancel-link {
  position: absolute;
  color: #ffffff;
  cursor: pointer;
  margin: 3px 0 0 -75px;
  font-family: "lato-regular";
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #14b9b8;
  }
}

.cancel-link:hover .white-img {
  background-image: url("../images/cancel-Icon-color.svg");
}

.cancel-link .white-img {
  background-size: 16px 16px;
  height: 30px;
  width: 19px;
  background-repeat: no-repeat;
  background-image: url("../images/cancel-Icon.svg");
  background-position: -1px 8px;
}

.voted-up {
  .accordion-toggle {
    .right-outline-icon {
      background-size: 16px 16px;
      cursor: pointer;
      height: 22px;
      width: 22px;
      background-repeat: no-repeat;
      background-image: url("../images/right.svg");
      background-position: 2px 3px;
      border: 1px solid #509ec6;
      border-radius: 3px;

      &:hover {
        background-color: #14b9b8;
        background-image: url("../images/right-bg.svg");
      }
    }
  }
}

.right-icon { 
  width: 20px;
  border: 1px solid #509ec6;
  padding: 2px;
  border-radius: 3px;
}

.down-thumb {
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

#dropdown-autoclose-inside {
  background-color: #ff000000;
  border-color: #ff000000;
  padding: 0;
  margin-top: -1px;
  margin-left: -1px;
}

.show>.btn-primary.dropdown-toggle:focus,
.btn-primary:focus {
  box-shadow: none !important;
}

.dropdown {
  .dropdown-toggle::after {
    display: none !important;
  }
}

img.searchin {
  position: absolute;
  margin-top: 11px;
  margin-left: 4px;
}

button.fill {
  padding-right: 15px;
  height: 28px;
  font-family: "lato-regular";
  font-size: 13px;
  color: #ffffff;
  border-radius: 3px;
  background-color: #14b9b8;
  margin-top: 9px;
  border: 1px solid #14b9b8;

  img {
    margin-right: 10px;
  }
}

a.modify {
  color: #fff;
  text-decoration: none;
  padding-left: 5px;
}

button.filter {
  height: 100%;
  font-family: "lato-regular";
  font-size: 13px;
  color: #ffffff;
  background: #14b9b8;
  margin-top: 10px;
  border: 1px solid #14b9b8;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-left: -3px;
}

.dropdown-menu.show.locaten {
  width: 315px;
  height: auto;
  border-radius: 3px;
  background: #366b91;
  color: #81a9cc;
  padding: 11%;
  font-family: "lato-regular";
  margin-left: -243px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  margin-top: -5px;
  inset: 0px auto auto 0px !important;
  transform: translate3d(13.6px, 36.8px, 0px) !important;
}

//header search end----------------------------------------------------------------->
span.checked {
    font-family: "lato-regular";
    background-color: #265d84;
    font-size: 0.688rem;
}

a.jacob {
  font-family: "lato-regular";
  font-size: 13px;
  color: #5f84a4;
  text-transform: uppercase;
  text-align: center;
  margin: 5px 0px 13px 20px;
  letter-spacing: 1px;
  border-bottom: 4px solid #134067;
  padding-top: 25px;
  padding-bottom: 11px;

  &:hover {
    color: #fff !important;
  }
}

a.nav-link.jacob.active {
  border-bottom: 4px solid #14b9b8 !important;
  color: #ffffff;
}

.modal {
  display: none;
  position: fixed;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 800px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
}

@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

.key {
  text-align: left;
  font-size: 13px;
  font-family: "lato-regular";
  margin-bottom: 30px;
  margin-left: 18px;
  .answers {
    margin-left: 2%;
    font-size: 13px;
    color: #526c80;
  }

  .questions {
    font-weight: bold;
    font-size: 13px;
    color: #526c80;
  }
}

.modal-body {
  padding: 15px 16px;
}

.emergency {
  font-family: "lato-regular";
  font-size: 15px;
  width: 100%;
  color: #526c80;
}

.linkedin {
  line-height: 1;
  padding: 0% 6% 1% 3%;
}

.edit-candidate-wrap {
  border-right: 1px solid #b2c8d875;
}

.stack {
  padding: 0% 6% 1% 3%;
  text-align: left;
}

.down-arrow {
  transform: rotate(90deg);
  cursor: pointer;
}

.down,
.sort-icon,
.delete {
  cursor: pointer;
}

.accordion-toggle.table-row,
.accordion-toggle.table-row[aria-expanded="false"] {
  td img.down {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: all .3s ease;
  }
}

.accordion-toggle.table-row[aria-expanded="true"] {
  td img.down {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    transition: all .3s ease;
  }
}

.sort-icon {
  rect {
    fill: #b2c8d8 !important;
  }
}

.bxmodel {
  width: 308px;
  height: 29px;
  padding: 0px 8px;
  border: 1px solid #b2c8d8;
  border-radius: 3px;
  background-color: #ffffff;
  background-size: cover;
  font-family: "lato-regular";
  font-size: 13px;
  color: #526c80;
}

h5#exampleModalLabel {
  font-family: "lato-regular";
  font-size: 13px;
  color: #ffffff;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
}

button.btn.btn-secondary.telenory {
  width: 82px;
  height: 32px;
  border-radius: 3px;
  background-color: #bedae6;
  font-family: "lato-regular";
  font-size: 13px;
  color: #134067;
  border: none;
}

button.btn.btn-secondary.telenory.closingfile:hover {
  background: #14b9b8;
  color: #fff;
}

a.tabss:hover {
  color: #526c80;
}

span.tabss {
  font-family: "lato-regular";
  cursor: pointer;
  font-size: 13px;
  color: #526c80;
  text-decoration: underline;
}

.github3 {
  width: 94%;
  margin: auto;
  height: 255px;
  padding: 8px;
  border: 1px solid #85b3cd;
  border-radius: 4px;
  background-color: #ffffff;
  font-family: "lato-regular";
  font-size: 14px;
  color: #526c80;
}

button.junkin {
  font-family: "lato-regular";
  font-size: 13px;
  color: #ffffff;
  text-align: center;
  width: 82px;
  height: 29px;
  border-radius: 3px;
  background: #509ec6;
  border: 1px solid #509ec6;
  margin: 20px;
}

.zombe {
  margin: 0 auto;
  display: table;
}

thead.Campaignname {
  background: #fff;
}

input.form-control.custodian.title {
  margin-left: -22px;
  width: 200px;
}

.educatab h4 {
  font-family: "lato-bold";
  font-size: 14px;
  color: #526c80;
}

li.universe {
  width: 213px;
  list-style: none;
  height: 16px;
  font-family: "lato-regular";
  font-size: 13px;
  color: #526c80;
}

ul.stanford {
  text-align: left;
  margin-left: -9%;
}

.rectangle {
  padding: 0%;
}

.datanotes {
  text-align: left;
  margin-left: 40px;
  margin-right: 40px;
}

.skillbuilding {
  background: #f8fbfd;
  text-align: left;
}

button.cancell {
  width: 100px;
}

li.universal {
  list-style: none;
  width: 213px;
  height: 16px;
  font-size: 13px;
  color: #526c80;
  font-style: italic;
  font-weight: bold;
}

button.apptab {
  background: #14b9b8;
  font-family: "lato-regular";
  font-size: 13px;
  color: #ffffff;
  text-align: center;
  width: 82px;
  height: 29px;
  border-radius: 3px;
  background-size: cover;
  border: navajowhite;
  margin-bottom: 15px;
}

a.tabzz:hover {
  color: #526c8087;
}

a.tabzz {
  color: #526c8087;
  font-family: "lato-regular";
  font-size: 13px;
  text-decoration: underline;
}

.skillearn {
  padding-right: 2%;
}

li.hobie2,
li.hobie3,
li.hobie4 {
  font-size: 13px;
  font-family: "lato-regular";
}

.clien {
  font-family: "lato-regular";
  font-size: 14px;
  color: #c93881;
  text-align: center;
}

.port3 {
  font-family: "lato-regular";
  font-size: 13px;
  color: #ecf3f6;
  text-align: center;
  margin: 0 auto;
  margin-left: 5%;
  text-transform: capitalize;
}

.port3.center {
  margin-left: 14%;
}

a.zuman {
  width: 120px;
  height: 15px;
  font-family: "lato-regular";
  font-size: 13px;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
}

a.zumans {
  margin: 40px;
  font-family: "lato-regular";
  font-size: 13px;
  color: #5f84a4;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
}

a.alexa {
  width: 132px;
  height: 16px;
  font-family: "lato-regular";
  font-size: 13px;
  color: #ffffff;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.manaz {
  background: #85b3cd;
  height: 36px;
  padding: 8px;
  text-align: center;
}

.manazs {
  background: #386892;
  height: 36px;
  margin-top: 8px;
  text-align: center;
}

img.pointer {
  background: #509ec6;
}

button.editab.editabx {
  margin-top: 12px;
  margin-left: 12px;
}

.extend {
  background: #fff;
  padding-top: 38px;
}

.dataclickp {
  background: #fff;
  padding-left: 5%;
}

.dataclutch {
  padding-left: 5%;
}

p.notaryfirm {
  width: 339px;
  height: 89px;
  font-family: "Lato-Regular";
  font-size: 13px;
  color: #526c80;
  text-decoration: none solid rgb(82, 108, 128);
  letter-spacing: 1px;
  padding-left: 5%;
}

p.notaryfirm:hover {
  background: #dde8ee;
}

p.text-start {
  width: 339px;
  height: 89px;
  font-family: "Lato-Regular";
  font-size: 13px;
  color: #526c80;
  text-decoration: none solid rgb(82, 108, 128);
  letter-spacing: 1px;
}

p.text-start2 {
  height: 89px;
  font-family: "Lato-Regular";
  font-size: 13px;
  color: #526c80;
  text-decoration: none solid rgb(82, 108, 128);
  letter-spacing: 1px;
}

p.text-start2:hover {
  background: #dde8ee;
}

.clicked9 {
  padding-left: 4%;
}

p.text-start:hover {
  background: #dde8ee;
}

.jbond {
  float: right;
}

.jbond5 h4 {
  font-family: "lato-bold";
  font-size: 17px;
  color: #526c80;
  margin-left: 6%;
}

.jbond h4 {
  font-family: "lato-bold";
  font-size: 17px;
  color: #526c80;
  margin-left: 18%;
}

a.epicdetail {
  color: #526c80;
}

ul.hobbie1 {
  list-style: none;
  font-family: "lato-regular";
  font-size: 13px;
  color: #526c80;
  line-height: 20px;
}

ul.hobbe1 {
  list-style: none;
  font-family: "lato-regular";
  font-size: 14px;
  color: #526c80;
  line-height: 20px;
}

.hobbie h4 {
  font-family: "lato-bold";
  font-size: 13px;
  color: #526c80;
}

.hobbie {
  padding-left: 4%;
  width: 272px;
}

ul.skill1 {
  list-style: none;
  padding-left: 3%;
}

ul.skil1 {
  list-style: none;
  padding-left: 3%;
  width: 288px;
  height: 80px;
  font-family: "lato-regular";
  font-size: 14px;
  color: #526c80;
  line-height: 20px;
}

.hobbie-title {
  font-family: "lato-regular";
  font-size: 13px;
  color: #526c80;
  font-weight: bold;
  margin-left: 30px;
}

.skill-title {
  font-family: "lato-regular";
  font-size: 13px;
  color: #526c80;
  font-weight: bold;
  margin-left: 13px;
}

button.vote {
  width: 132px;
  height: 29px;
  border-radius: 3px;
  background: #509ec6;
  margin: 0px 2px 1px 20px;
  font-family: "lato-regular";
  font-size: 13px;
  color: #ffffff;
  text-align: center;
  border: none;

  a {
    color: white;
    text-decoration: none;
    margin-left: 10px;
  }
}

.profiledisk {
  margin: 0 auto;
  margin-top: 4%;
  margin-right: 7%;
  text-align: right;
}

.huddle {
  width: 51% !important;
  height: 39px;
  margin-top: 10px;
  background: #134067 !important;
  border: 1px solid #5b7e95 !important;
}

button.fullfledg {
  width: 113px;
  height: 40px;
  font-family: "lato-regular";
  font-size: 13px;
  color: #ffffff;
  background: #366b91;
  margin-top: 9px;
  border: 1px solid #366b91;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-left: -3px;
}

button.appletab {
  background: #14b9b8;
  font-family: "lato-regular";
  font-size: 13px;
  color: #ffffff;
  text-align: center;
  width: 102px;
  height: 40px;
  border-radius: 3px;
  background-size: cover;
  border: navajowhite;
  padding: 6px;
}

.sort-down-icon {
  transform: rotate(180deg);
}

li.skill5,
li.skill1,
li.skill2,
li.skill3,
li.skill4,
.hobbie2,
.hobbie3,
.hobbie4 {
  border: 1px solid #b2c8d8;
  margin-bottom: 17px;
  padding: 2%;
  border-radius: 3px;
}

li.skil5,
li.skil1,
li.skil2,
li.skil3,
li.skil4,
.hobbe2,
.hobbe3,
.hobbe4 {
  margin-bottom: 17px;
  padding: 2%;
  border-radius: 3px;
  font-size: 13px;
  font-family: "lato-regular";
}

ul.skil1d {
  list-style: none;
}

.skillearn h4 {
  font-family: "lato-regular";
  font-size: 13px;
  color: #526c80;
}

h4.educatab {
  font-family: "lato-bolditalic";
  font-size: 15px;
  color: #526c80;
}

.disc1 {
  color: #fff;
  width: 29px;
  height: 29px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  float: right;
  margin-top: -15%;
}

.plus-container {
  margin-top: 12px;
  width: 29px;
  height: 29px;
  text-align: center;
  background: #509ec6;
  border-radius: 3px;
  cursor: pointer;
}

.minusBox {
  width: 21px;
  height: 21px;
  border-radius: 3px;
  fill: #509ec6;
}

.accordion-toggle td .minus-sign::after {
  content: "-";
  color: #ffffff;
  font-size: 28px;
  position: absolute;
  margin: -11px 0 0 -15px;
}

.plusBox.plus-ico {
  width: 21px;
  height: 21px;
  border-radius: 3px;
  background-color: #509ec6;
}

.plusBox.plus-ico::after {
  content: "+";
  color: #ffffff;
  font-size: 22px;
  position: absolute;
  margin: -7px 0 0 4px;
}

span.plus-icon {
  font-size: 21px;
  color: #ffffff;
  text-align: center;
}

a.epicdetail:hover {
  color: #526c80;
}

nav.navbar.navbar-expand-lg.navbar-dashboard {
  background: #134067;
  height: 50px !important;
}

.form-control {
  font-size: 13px !important;
}

input.form-control.custodian {
  width: 100%;
  height: 29px;
  padding: 0px 8px;
  border: 1px solid #b2c8d8;
  border-radius: 3px;
  background-color: #ffffff;
  background-size: cover;
  font-family: "lato-regular";
  font-size: 13px;
  color: #526c80;
  text-decoration: none solid rgb(82, 108, 128);
}

input.form-control.custodian2,
label.input-group-text.custodian2 {
  height: 31px;
  width: 100%;
  font-size: 13px;
  font-family: "lato-regular";
}

.multi-select {
  width: 152px !important;
  height: 29px;
  padding: 0px 8px !important;
  border: 1px solid #b2c8d8 !important;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  background-color: #ffffff;
  font-family: "lato-regular";
  font-size: 13px;
  color: #526c80 !important;
}

.custom-select-loca {
  width: 51px;
  height: 29px;
  outline: none;
  border: 1px solid #b2c8d8 !important;
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
  font-family: "lato-regular";
  font-size: 13px;
  color: #526c80;
  position: absolute;
}

.skill-field {
  margin-top: 8px;
  width: 324px !important;
  height: 29px;
  padding: 0px 8px !important;
  border: 1px solid #b2c8d8 !important;
  border-radius: 3px !important;
  background-color: #ffffff !important;
  font-family: "lato-regular";
  font-size: 13px !important;
  color: #526c80 !important;
}

.custom-select {
  width: 64px !important;
  height: 29px;
  border: 1px solid #b2c8d8 !important;
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
  font-family: "lato-regular";
  font-size: 13px;
  color: #526c80;
}

select {
  &.custom-select {
    &.explore {
      width: 30% !important;
    }
  }
}

tr.table-heading {
  background: #fff;
}

select.custom-select.cityclass {
  width: 247px;
  height: 29px;
  padding: 0px 8px;
  border-radius: 4px;
  font-family: Lato;
  font-size: 13px;
  color: #526c80;
}

/* client css */
tr.resoucesdata {
  background: #fff;
}

tr.dynamic:hover {
  background: #85b3cd !important;
}

a.tabss:hover {
  color: #526c80;
}

a.tabss {
  font-family: "lato-regular";
  font-size: 13px;
  color: #526c80;
  text-decoration: underline;
}

.port {
  font-family: "lato-regular";
  font-size: 13px;
  color: #ecf3f6;
  text-transform: capitalize;
  margin: auto;
  margin-right: 42%;
}

.form-control-plaintext {
  font-family: "lato-regular";
  font-size: 13px;
  color: #526c80 !important;
}

.edit-section {
  background: #f8fbfd !important;

  .extendedrev {
    background: #f8fbfd !important;
  }
}

button {
  &.btn {
    &.btn-secondary {
      &.telenory {
        &.closer {
          &:hover {
            background: #14b9b8;
            color: #fff;
          }
        }
      }
    }
  }
}

.hiddenRow {
  padding: 0 !important;
  background: #f8fbfd;
}

/* client css */
//edit-candidate
.edit {
  background: #14b9b8 !important;
}

.camp-desc {
  margin-bottom: 0;
  padding: 2px 5px;
  cursor: pointer;

  &:hover {
    background: #b2c8d8;
    width: fit-content;
    padding: 2px 5px;
    border-radius: 3px;
  }
}

.edit-candidate-main {
  font-family: "lato-regular";
  font-size: 15px;
  width: 100%;
  color: #526c80;
  padding: 30px;
}

.edit-candidate-extendedrev {
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
}

.modal-dialog.result {
  .modal-content {
    width: 310px !important;
    padding: 10px;
    border-radius: 3px;
    border: 2px solid #81b9d5;
  }

  .modal-footer {
    border-top: none !important;
    display: flex;
    margin: auto;

    .result {
      padding: 0;
    }
  }
}

.modal-dialog.culture-fit {
  .modal-content {
    width: 350px !important;
    padding: 10px;
    border-radius: 3px;
    border: 2px solid #81b9d5;
  }

  .input-culturefit {
    display: flex;
  }

  .culture {
    margin-right: 20px;
    font-size: 14px;
    color: #526c80;
    font-family: "lato-regular";
  }

  .select-culturefit {
    width: 44px;
    height: 29px;
    border: 1px solid #b2c8d8;
    border-radius: 3px;
    font-size: 13px;
    color: #526c80;
  }

  .modal-footer {
    border-top: none !important;
    display: flex;
    margin: auto;

    .culturefit-footer {
      padding: 0;
    }
  }
}

.deletebin {
  margin-top: 78px;
}

.resume-file {
  margin-top: 146px;
  margin-left: -11%;
}

.edit-label {
  font-family: "lato-regular";
  font-size: 13px !important;
  color: #526c80;
  width: 115px !important;
}

.edit-candidate-label {
  font-family: "lato-regular";
  font-size: 13px !important;
  color: #526c80;
}
.social-links {
  img {
    margin: 6px 0;
    cursor: pointer;
  }
  img.fade-link {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
.social-media-section {
  padding: 25px 80px 0 0; 
  .link-icon {
    margin: 0 15px 0 0;
  }
  .link-field {
    width: 90%;
  }
  .form-check {
    margin: 0 0 0 15px;
    .form-check-input {
      width: 1.5em;
      height: 1.5em;
    }
  }
  /* The container-checkbox */
  .container-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: not-allowed;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: -23px 0 0 15px;
  }
  
  /* Hide the browser's default checkbox */
  .container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: not-allowed;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 23px;
    width: 23px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #b2c8d8;
  }
  
  /* On mouse-over, add a grey background color */
  .container-checkbox:hover input ~ .checkmark {
    background-color: #ffffff;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container-checkbox input:checked ~ .checkmark {
    background-color: #ffffff;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container-checkbox input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container-checkbox .checkmark:after {
    left: 8px;
    top: 3px;
    width: 6px;
    height: 12px;
    border: solid #14b9b8;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.education-label {
  font-family: "lato-regular";
  font-size: 10px !important;
  color: #526c80;
}

p.fileresum {
  float: right;
  padding-right: 10%;
  font-family: 'lato-bold';
}

b,
strong {
  font-weight: bolder;
  margin-left: 0% !important;
}

.years {
  margin: 7px;
  margin-left: 12px !important;
  font-family: "lato-regular";
  font-size: 13px;
  color: #526c80;
}

.binbox {
  margin-left: 20px;
}

.skills {
  margin-left: -10px;
}

.skills-hobby {
  margin: auto !important;
  margin-top: 0 !important;
}

.input-button {
  width: 250px !important;
  height: 29px !important;
  border-radius: 3px;
  background-color: #ffffff;
  font-family: "lato-regular";
  font-size: 13px;
  color: #526c80;

  .form-control {
    height: 29px;
    border: 1px solid #b2c8d8;
    font-size: 12px;
  }

  .btn-bin {
    height: 29px;
    border: 1px solid #b2c8d8;
    background: #509ec6;
    margin-bottom: 20px !important;
  }
}

.education-section {
  margin-left: 20px;
}

.valuelabs {
  min-width: auto;
}

.apply {
  width: 75px;
  height: 29px;
  border-radius: 3px;
  background-color: #14b9b8;
  border: none;
  font-size: 12px;
  font-family: "lato-regular";
  color: #ffffff;
  text-align: left;

  img {
    margin-right: 10px;
  }
}

//result modal css
.decision-btn {
  width: 82px;
  height: 29px;
  border-radius: 3px;
  background-color: #509ec6;
  color: #ffffff;
  border: none;
  font-family: "lato-regular";
  margin-right: 15px !important;
  margin-left: 15px !important;
}

.result-heading {
  font-family: "lato-regular";
  font-size: 14px;
  color: #526c80;
  text-align: center;
}

.vote-cand-id {
  font-family: "lato-bold";
  font-size: 13px;
  color: #526c80;
  text-align: center;
  margin-left: 20px;
}

.center-text {
  margin-left: 20px;
}

.act-center {
  text-align: center;
}

.back-heading {
  font-family: "lato-regular";
  font-size: 13px;
  color: #5f84a4 !important;
  letter-spacing: 1px;
  text-transform: uppercase;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom {
  right: 64px;
  top: 0;
}

.act-res {
  margin-left: 5px;
}

.edit-avatar {
  position: relative;
  cursor: pointer;
}

.edit-input {
  position: absolute;
  left: 0;
  opacity: 0;
  cursor: pointer;
  padding-top: 40px;
  padding-bottom: 37px;
  width: 100px;
}

.upload-picture {
  left: 75px;
  top: 96px;
  padding-top: 43px;
  padding-bottom: 30px;
}

.storage-edit-candidate {
  background-color: #f8fbfd;
  padding: 20px;
  .edit-input { 
    padding-bottom: 20px;
    width: 80px;
  }
  .trash-icon {
    margin-top: -25px;
    margin-left: 5px;
  }
  .resume-field {
    width: 70px;
    display: flex;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 992px) {
  .resume-file {
    margin-top: 0;
  }

  .stack {
    border-right: none;
  }

  .skills-hobby {
    margin: 0 !important;
  }
}