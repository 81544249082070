@font-face {
  font-family: "lato-bold";
  src: url("../fonts/lato-bold.woff") format("woff2"),
    url("../fonts/lato-bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "lato-light";
  src: url("../fonts/lato-light.woff2") format("woff2"),
    url("../fonts/lato-light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
} 
@font-face {
  font-family: "lato-regular";
  src: url("../fonts/lato-regular.woff2") format("woff2"),
    url("../fonts/lato-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "lato-black";
  src: url("../fonts/lato-black.woff2") format("woff2"),
    url("../fonts/lato-black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "lato-semibold";
  src: url("../fonts/lato-semibold.woff2") format("woff2"),
    url("../fonts/lato-semibold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "lato-heavy";
  src: url("../fonts/lato-heavy.woff2") format("woff2"),
    url("../fonts/lato-heavy.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "lato-medium";
  src: url("../fonts/lato-medium.woff2") format("woff2"),
    url("../fonts/lato-medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
} 
body {
  font-family: "lato-regular";
  font-weight: normal;
  font-style: normal;
  overflow-x: hidden;  
} 

.login-form {
    .form-group {
      &.field_cont {
        height: 5rem;
        margin-bottom: 10px;
      }
    }
  }
  
  .btn.btn-link:focus{
    box-shadow: none;
    padding-bottom: 2px;
  }
  
  button:disabled {
    cursor: not-allowed;
  }
  
  .error_msg {
    color: #ff0000;
    padding-top: 2px;
    font-size: 12px;
  }
  
  .verify-page .login-form .btn {
    margin: 35px auto 30px;
  }
  .forgot-password-page .card .card-body .login-form .btn {
    margin: 5px auto 30px;
    cursor: pointer;
  }
.Login-page .Banner {
    background-image: url("../images/login-page-banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
  }
  
  .Login-page .main-container{
    background: #ffffff !important;
  }
  
  .Login-page .back-link,
  .BasicIngo-Page .header-text,
  .Password-Creation-Page .header-text,
  .Survey-Page .header-text {
    font-family: "lato-medium";
    font-size: 0.875rem;
    line-height: 1.19;
    letter-spacing: 0.8px;
    color: #4c677b;
    display: flex;
    align-items: center;
    margin-right: 50px;
    .arrow {
      font-size: 16px;
      color: #4c677b;
      margin-right: 5px;
    }
    a {
      color: #14b9b8;
    }
  }
  
  .Login-page .bank-arrow-position {
    margin: 15px 0 0 30px;
  }
  
  .Login-page .login-page-logo {
    width: 275px;
    display: block;
    margin: 20px auto 30px;
  }
  
  .Login-page .card {
    width: 60%;
    margin: 50px auto 0;
    border: none;
  }
  
  .password-verification .card {
    width: 60%;
    margin: 125px auto 0;
    border: none;
  }
  
  .password-verification .card-body .input-group-prepend .input-group-text {
    color: #b2c8d8;
    padding: 0 5px;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-bottom: 1.3px solid #509ec6;
    border-radius: 0;
  }
  
  .Login-page .welcome-back-label, .verify-page .verification-label {
    font-family: "lato-bold";
    font-size: 1.625rem;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #134067;
    margin: 10px auto;
  }
  
  .Login-page .please-login-line, .verify-page .please-login-line {
    font-family: "lato-medium";
    font-size: 1.125rem;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: center;
    color: #134067;
    margin-bottom: 50px;
  }
  
  .verify-page .please-login-line {
    margin-bottom: 10px;
  }
  
  .password-verification .please-login-line {
    margin-bottom: 60px;
  } 
  .Login-page .login-form,
  .Password-Creation-Page .password-creation-form {
    line-height: 1.19;
    letter-spacing: 0.8px;
    text-align: left;
    color: #4c677b;
    .form-label {
      font-family: "lato-medium";
      font-size: 0.875rem;
      margin-bottom: 0px;
    }
    .form-control {
      border: none;
      border-bottom: 1.3px solid #509ec6;
      border-radius: 0;
      padding: 0;
      font-size: 1.188rem;
      color: #4c677b;
      margin-bottom: 2px;
    }
    .form-control::placeholder {
      line-height: 1.21;
      letter-spacing: 0.48px;
      text-align: left;
      color: #b2c8d8;
      font-size: 14px;
    }
    .form-control:focus::-webkit-input-placeholder {
      color: #4c677b;
    }
    .form-control:focus:-moz-placeholder {
      color: #4c677b;
    }
    .form-control:focus::-moz-placeholder {
      color: #4c677b;
    }
    .form-control:focus:-ms-input-placeholder {
      color: #4c677b;
    }
    .form-control:focus {
      box-shadow: none;
      background-color: rgba(20, 185, 184, 0.11);
    }
    a.forgot-password {
      float: right;
      font-family: "lato-medium";
      font-size: 0.875rem;
      line-height: 1.19;
      letter-spacing: 0.8px;
      color: #4c677b;
      cursor: pointer;
    }
  }
  .Login-page .login-form .btn {
    background-color: #14b9b8;
    font-family: "lato-bold";
    line-height: 1.19;
    letter-spacing: 0.8px;
    text-align: center;
    color: #ffffff;
    padding: 10px;
    width: 100%;
    border-radius: 3px;
    margin: 35px auto 30px;
  }
  .Login-page .login-form .btn:hover {
    color: #ffffff;
  }
  
  .Login-page .sign-up-link {
    color: #4c677b;
    font-family: "lato-medium";
    line-height: 1.24;
    letter-spacing: 0.43px;
    text-align: center;
    .sign-up {
      font-family: "lato-bold";
      color: #14b9b8;
    }
  }
  
  .Login-page .content-background {
    background-image: linear-gradient(to top, #052f53, rgba(1, 27, 46, 0) 75%);
    height: 100vh;
  }
  
  .Login-page .banner-content {
    position: absolute;
    bottom: 0;
    margin-bottom: 100px;
    padding-left: 75px;
    .banner-heading {
      font-family: "lato-bold";
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
      font-size: 2.375rem;
    }
    .blueDash {
      padding: 0.2em 0 0;
      width: 2em;
      background-color: #14b9b8;
    }
    .banner-tagline {
      font-family: "lato-medium";
      line-height: 1.22;
      letter-spacing: 0.58px;
      text-align: left;
      width: 400px;
      color: #ffffff;
      margin: 20px 0;
    }
  }
  .Login-page .login-form .btn {
    padding: 15px;
  }
  .verify-page .login-form .btn {
    margin-top: 100px;
  }
  .Login-page .please-login-line,
  .verify-page .please-login-line {
    font-size: 1.313rem;
  }
  .Login-page .login-form .form-label,
  .Login-page .login-form a.forgot-password {
    font-size: 1rem;
    cursor: pointer;
  }
  .Login-page .bank-arrow-position {
    margin: 15px 0 0 10px;
  }
  .Login-page .login-form, .Password-Creation-Page .password-creation-form {
    .form-control {
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }
