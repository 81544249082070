#notfound-page{
    background: #DDE8EE;
}
.label-404 {
    margin-top: 50px;
  h2 {
    font-size: 96px;
    font-family: "lato-regular";
    z-index: 0;
  }
}
.page-label {
  z-index: 1;
  background: #ffffff;
  font-size: 18px;
  font-family: "lato-italic";
  font-style: italic;
  text-align: center;
  position: relative;
  bottom: 75px;
  background: beige;
}
.backbtn {
  position: relative;
  bottom: 20px;
  .back-button {
    width: 150px;
  }
}
